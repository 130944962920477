import {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  useMemo,
  useRef
} from 'react';
import BaseModal, { Props as BaseModalProps } from 'react-modal';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { Close20 } from '@elfsight/icons';
import { useTheme } from '../../theme';
import { IconButton } from '../buttons';
import { useWindowHeight } from '../../utils';
import { Loader } from '../loader';
import { customScrollbarMixin } from '../custom-scrollbar-mixin';

BaseModal.setAppElement('#__next');

const MODAL_OVERLAY_CLASS_NAME = 'ReactModal__Overlay';
export const MODAL_OPEN_BODY_CLASS_NAME = 'ReactModal__Body--open';
export const Y_OFFSET = 90;

type TitleAlign = 'left' | 'center';
type Theme = 'default' | 'clear';

export type ModalProps = PropsWithChildren<BaseModalProps> & {
  title?: ReactNode;
  actions?: ReactNode;
  maxWidth?: number;
  withClose?: boolean;
  closeColor?: string;
  padding?: [number, number];
  zIndex?: number;
  isLoading?: boolean;
  containerComponent?: typeof DefaultContainer;
  titleAlign?: TitleAlign;
  withoutBoxShadow?: boolean;
  theme?: Theme;
  yOffset?: number;
  minHeight?: number;
};

export function Modal({
  children,
  title,
  actions,
  maxWidth = 400,
  isOpen,
  withClose,
  closeColor,
  padding = [20, 24],
  onRequestClose,
  zIndex,
  isLoading,
  style,
  titleAlign = 'center',
  containerComponent: Container = DefaultContainer,
  withoutBoxShadow,
  yOffset = Y_OFFSET,
  theme = 'default',
  minHeight,
  ...forwardingProps
}: ModalProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const { colors, zIndex: themeZIndex } = useTheme();
  const windowHeight = useWindowHeight() || 0;

  const maxAvailableHeight = windowHeight - yOffset;
  const finalHeight = useMemo(() => {
    if (!minHeight) {
      return;
    }

    return minHeight < maxAvailableHeight ? minHeight : maxAvailableHeight;
  }, [minHeight, maxAvailableHeight]);

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      style={{
        ...style,
        content: {
          ...modalContentStyle,
          ...style?.content
        },
        overlay: {
          zIndex: zIndex || themeZIndex.modalDefault,
          background: colors.gray50,
          ...style?.overlay
        }
      }}
      {...forwardingProps}
    >
      <CSSTransition
        nodeRef={contentRef}
        classNames="slide-in"
        timeout={0}
        in
        appear
      >
        <>
          {withClose && theme === 'clear' && (
            <CloseButton
              rounded
              icon={Close20}
              onClick={onRequestClose}
              customColor={closeColor}
              _theme={theme}
              secondary
            />
          )}
          <ContainerWrapper
            style={{
              maxHeight: maxAvailableHeight ? maxAvailableHeight : '100vh',
              height: finalHeight
            }}
            ref={contentRef}
            _maxWidth={maxWidth}
            _withoutBoxShadow={withoutBoxShadow}
            _yOffset={yOffset}
            onClick={(e) => e.stopPropagation()}
          >
            <Container _padding={padding} _theme={theme}>
              {withClose && theme === 'default' && (
                <CloseButton
                  large
                  rounded
                  icon={Close20}
                  onClick={onRequestClose}
                  customColor={closeColor}
                  _theme={theme}
                />
              )}

              {isLoading && <Loader />}

              {title && <Title textAlign={titleAlign}>{title}</Title>}
              {children}
              {actions && <Actions>{actions}</Actions>}
            </Container>
          </ContainerWrapper>
        </>
      </CSSTransition>
    </BaseModal>
  );
}

const modalContentStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  boxSizing: 'border-box',
  background: 'transparent',
  pointerEvents: 'none',
  inset: 0,
  border: 'none',
  padding: '0 20px'
};

const CloseButton = styled(IconButton)<{ _theme?: Theme }>`
  z-index: ${({ theme }) => theme.zIndex.modalDefault};

  ${({ _theme }) =>
    _theme === 'default' &&
    css`
      position: absolute;
      right: 8px;
      top: 8px;
  `}

  ${({ _theme }) =>
    _theme === 'clear' &&
    css`
      position: fixed;
      right: 16px;
      top: 16px;
  `}
`;

export const DefaultContainer = styled.div<{
  _padding?: [number, number];
  _theme?: Theme;
}>`
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  border-radius: 8px;
  padding: ${({ _padding = [20, 24] }) => `${_padding[0]}px ${_padding[1]}px`};

  ${({ _theme }) =>
    _theme === 'default' &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
  `}

  ${({ _theme }) =>
    _theme === 'clear' &&
    css`
      background-color: transparent;
  `}

  ${customScrollbarMixin};
`;

const ContainerWrapper = styled.div<{
  _maxWidth?: number;
  _withoutBoxShadow?: boolean;
  _yOffset: number;
}>`
  position: relative;
  box-sizing: border-box;
  pointer-events: all;
  border-radius: 8px;
  ${({ theme }) => theme.font.text};
  width: 100%;
  overflow-y: hidden;

  ${({ _maxWidth }) =>
    _maxWidth &&
    css`
      max-width: ${_maxWidth}px;
    `}

  ${({ _withoutBoxShadow }) =>
    !_withoutBoxShadow &&
    css`
      box-shadow:  0 4px 16px 0 rgba(0, 0, 0, 0.3);
    `};

  transform: translateY(24px);
  opacity: 0;
  transition: all 200ms ease-in-out;

  .${MODAL_OVERLAY_CLASS_NAME}--after-open & {
    transform: translateY(0);
    opacity: 1;
  }

  .${MODAL_OVERLAY_CLASS_NAME}--before-close & {
    transform: translateY(24px);
    opacity: 0;
  }
`;

const Title = styled.div<{ textAlign: TitleAlign }>`
  ${({ theme }) => theme.font.title2};
  text-align: ${({ textAlign }) => textAlign};
  margin-bottom: 8px;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 12px;

  @media (max-width: 400px) {
    flex-direction: column-reverse;
  }
`;
