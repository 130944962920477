import { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  getColorWithAlpha,
  getThemeOrCustomColor,
  ThemeColor
} from '@elfsight-universe/ui-common';

export type ChipProps = HTMLAttributes<HTMLSpanElement> &
  PropsWithChildren<{
    color?: ThemeColor | string;
    textColor?: ThemeColor | string;
    backgroundColor?: ThemeColor | string;
    backgroundColorOpacity?: number;
    borderColor?: ThemeColor | string;
    yOffset?: number;
  }>;

export const Chip = forwardRef<HTMLSpanElement, ChipProps>(function Chip(
  {
    children,
    color,
    textColor = 'gray50',
    backgroundColor = 'gray5',
    backgroundColorOpacity = 0.1,
    borderColor,
    yOffset = -2,
    ...forwardingProps
  },
  forwardingRef
) {
  const theme = useTheme();
  const finalTextColor = getThemeOrCustomColor(color || textColor, theme);
  const finalBackgroundColor = getColorWithAlpha(
    getThemeOrCustomColor(color || backgroundColor, theme),
    backgroundColorOpacity
  );
  const finalBorderColor = borderColor
    ? getThemeOrCustomColor(borderColor, theme)
    : 'transparent';

  return (
    <Container
      _textColor={finalTextColor}
      _backgroundColor={finalBackgroundColor}
      _borderColor={finalBorderColor}
      _yOffset={yOffset}
      {...forwardingProps}
      ref={forwardingRef}
    >
      {children}
    </Container>
  );
});

const Container = styled.span<{
  _textColor: ThemeColor | string;
  _backgroundColor: ThemeColor | string;
  _borderColor: ThemeColor | string;
  _yOffset: number;
}>`
  user-select: none;
  ${({ theme }) => theme.font.captionSmallUppercase};
  display: inline-block;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  margin-top: ${({ _yOffset }) => _yOffset}px;
  padding: 2px 6px;
  border-radius: 32px;
  color: ${({ _textColor }) => _textColor};
  background: ${({ _backgroundColor }) => _backgroundColor};
  box-shadow: 0 0 0 1px ${({ _borderColor }) => _borderColor} inset;
`;
