import {
  MouseEvent,
  useEffect,
  useRef,
  HTMLAttributes,
  forwardRef
} from 'react';
import { mergeRefs } from 'react-merge-refs';
import styled, { css } from 'styled-components';
import { Button, ButtonContent, MenuButtonProps } from '../buttons';
import { useTabs } from './tabs-provider';

export type TabsItemProps = HTMLAttributes<HTMLDivElement> &
  MenuButtonProps & {
    id: string;
    onStateChange: (rect: DOMRect) => void;
    large: boolean;
    light: boolean;
    alert?: boolean;
    withBoldTextWidthFix?: boolean;
    boldText?: string;
    height?: number;
  };

export const TabsNavItem = forwardRef<HTMLDivElement, TabsItemProps>(
  function TabsNavItem(
    {
      onStateChange,
      id,
      large,
      light,
      alert,
      height,
      withBoldTextWidthFix,
      boldText,
      children,
      ...forwardingProps
    },
    forwardingRef
  ) {
    const { activeTab, setActiveTab } = useTabs();
    const ref = useRef<HTMLDivElement>(null);
    const mergedRef = mergeRefs([forwardingRef, ref]);

    const active = activeTab === id;

    useEffect(() => {
      if (active && ref.current) {
        onStateChange(ref.current.getBoundingClientRect());
      }
    }, [active, onStateChange]);

    const handleClick = (e: MouseEvent) => {
      e.preventDefault();
      if (ref.current) {
        onStateChange(ref.current.getBoundingClientRect());
      }
      setActiveTab(id);
    };

    const type = (large && 'large') || (light && 'light') || 'default';

    const fontWeight = { large: 600, light: 400, default: 500 }[type];
    const fontWeightActive = { large: 600, light: 600, default: 500 }[type];
    const fontSize = { large: 18, light: undefined, default: undefined }[type];
    const textColor = { large: 'gray50', light: 'gray70', default: 'gray70' }[
      type
    ];

    return (
      <Container ref={mergedRef}>
        <StyledButton
          padding={12}
          negativeOffset={-12}
          large={large}
          fontSize={fontSize}
          fontWeight={active ? fontWeightActive : fontWeight}
          textColor={active ? 'black' : textColor}
          interactTextColor="black"
          active={false}
          height={height}
          fullWidth
          onClick={handleClick}
          $withBoldTextWidthFix={withBoldTextWidthFix}
          $boldText={boldText}
          center
          {...forwardingProps}
        >
          {children}
          {alert && !active && <AlertDot />}
        </StyledButton>
      </Container>
    );
  }
);

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)<{
  $withBoldTextWidthFix?: boolean;
  $boldText?: string;
}>`

  ${ButtonContent} {
    &:hover,
    &:active {
      transition: all 0.2s, font-weight 0s;
    }
  }

  ${({ $boldText, $withBoldTextWidthFix }) =>
    $withBoldTextWidthFix &&
    $boldText &&
    css`
      &::after {
        display: block;
        content: '${$boldText}';
        font-weight: 600;
        overflow: hidden;
        visibility: hidden;
        height: 0;
        color: transparent;
        padding: 0 12px;
      }
    `}
`;

const AlertDot = styled.span`
  position: absolute;
  right: 7px;
  top: 5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.alert};
`;
