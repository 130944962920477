import styled, { css } from 'styled-components';
import { ElementType, forwardRef, HTMLAttributes } from 'react';
import { FontType, ThemeColor } from '../theme';

type TypographyProps = HTMLAttributes<
  HTMLParagraphElement | HTMLHeadingElement
> & {
  variant: FontType;
  gt?: boolean;
  gb?: boolean;
  mt?: number;
  mb?: number;
  alignCenter?: boolean;
  color?: ThemeColor;
  secondary?: boolean;
  as?: ElementType;
};

const COMPONENT_MAP = {
  title0: 'h1',
  title1: 'h1',
  title2: 'h2',
  title3: 'h3',
  title4: 'h4',
  text: 'p',
  text2: 'p'
} as const;

const GUTTER = 12;

export const Typography = forwardRef<
  HTMLParagraphElement | HTMLHeadingElement,
  TypographyProps
>(function Typography(
  {
    variant = 'text',
    gt,
    gb,
    mt,
    mb,
    secondary,
    alignCenter,
    color,
    as,
    ...forwardingProps
  },
  forwardingRef
) {
  const component = COMPONENT_MAP[variant as keyof typeof COMPONENT_MAP];

  return (
    <TypoElement
      _font={variant}
      _secondary={secondary}
      _marginTop={gt ? GUTTER : mt}
      _marginBottom={gb ? GUTTER : mb}
      _alignCenter={alignCenter}
      _color={color}
      as={as || component || 'span'}
      {...forwardingProps}
      ref={forwardingRef}
    />
  );
});

type TypoElementProps = {
  _font: FontType;
  _secondary?: boolean;
  _marginBottom?: number;
  _marginTop?: number;
  _alignCenter?: boolean;
  _color?: ThemeColor;
};

const TypoElement = styled.span<TypoElementProps>`
  margin: 0;
  ${({ _font, theme }) => theme.font[_font]};
  color: ${({ _secondary, _color = 'black', theme }) =>
    _secondary ? theme.colors.gray70 : theme.colors[_color]};

  ${({ _alignCenter }) =>
    _alignCenter &&
    css`
      text-align: center;
    `};

  ${({ _marginBottom }) =>
    _marginBottom &&
    css`
      margin-bottom: ${_marginBottom}px;
    `};
  ${({ _marginTop }) =>
    _marginTop &&
    css`
      margin-top: ${_marginTop}px;
    `};
`;

export const H1 = (props: Omit<TypographyProps, 'variant'>) => (
  <Typography variant="title1" {...props} />
);
export const H2 = (props: Omit<TypographyProps, 'variant'>) => (
  <Typography variant="title2" {...props} />
);
export const H3 = (props: Omit<TypographyProps, 'variant'>) => (
  <Typography variant="title3" {...props} />
);
export const H4 = (props: Omit<TypographyProps, 'variant'>) => (
  <Typography variant="title4" {...props} />
);

type PProps = Omit<TypographyProps, 'variant'> & { big?: boolean };
export const P = ({ big, ...props }: PProps) => (
  <Typography variant={big ? 'text2' : 'text'} {...props} />
);
