import { useRef } from 'react';
import useCopyClipboard from 'react-use-clipboard';
import styled, { css } from 'styled-components';
import { Copy16 } from '@elfsight/icons';
import { getColorWithAlpha } from '../utils';
import { useTheme } from '../theme';
import { Button } from './buttons';
import { Popover } from './popovers';
import { Icon } from './icons';

export type CopiedCodeProps = {
  children: string | number;
  color: string;
  textColor: string;
  borderColor?: string;
  buttonText?: string;
  copiedText?: string;
  disabled?: boolean;
  onCopyClick?: () => void;
  onCodeDoubleClick?: () => void;
};

export function CopiedCode({
  children,
  color,
  textColor,
  borderColor = color,
  buttonText = 'Copy',
  copiedText = 'Copied',
  disabled,
  ...forwardingProps
}: CopiedCodeProps) {
  const codeRef = useRef<HTMLElement>(null);
  const [isCopied, copyTextToClipboard] = useCopyClipboard(
    children.toString(),
    {
      successDuration: 1000
    }
  );

  const { zIndex: themeZIndex } = useTheme();

  const handleDoubleClick = () => {
    if (!codeRef.current) return;

    const range = document.createRange();
    range.selectNodeContents(codeRef.current);

    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const showButtonText = buttonText.length > 0;

  return (
    <Container
      _color={color}
      _borderColor={borderColor}
      _disabled={disabled}
      {...forwardingProps}
    >
      {!disabled &&
        (showButtonText ? (
          <FloatingButton
            leftIcon={Copy16}
            iconSize={16}
            backgroundColor={color}
            onClick={copyTextToClipboard}
            small
            height={28}
            disabled={disabled}
          >
            {buttonText}
          </FloatingButton>
        ) : (
          <FloatingButton
            backgroundColor={color}
            onClick={copyTextToClipboard}
            small
            height={28}
            disabled={disabled}
            padding={6}
          >
            <Icon component={Copy16} size={16} />
          </FloatingButton>
        ))}

      <Popover
        isOpen={isCopied}
        placement="top"
        containerStyle={{ zIndex: themeZIndex.coupon.toString() }}
        preventFocus
        content={<Copied>{copiedText}</Copied>}
      >
        <Pre
          onDoubleClick={handleDoubleClick}
          _rightOffset={showButtonText ? 16 : 40}
        >
          <Code _textColor={textColor} ref={codeRef}>
            {children}
          </Code>
        </Pre>
      </Popover>
    </Container>
  );
}

const FloatingButton = styled(Button)`
  position: absolute;
  right: 4px;
  top: 4px;
`;

const Container = styled.div<{
  _color: string;
  _borderColor: string;
  _disabled?: boolean;
}>`
  position: relative;
  border-style: solid;
  border-width: 1px;
  transition: all 0.2s;
  border-radius: 4px;

  ${({ _disabled }) =>
    _disabled &&
    css`
      user-select: none;
      mix-blend-mode: luminosity;
      opacity: 0.5;
    `}

  ${({ _color, _borderColor }) => css`
    background-color: ${getColorWithAlpha(_color, 0.05)};
    border-color: ${_borderColor};
  `}
`;

const Pre = styled.pre<{ _rightOffset?: number }>`
  padding: 16px;
  padding-right: ${({ _rightOffset = 16 }) => `${_rightOffset}px`};
  margin: 0;
  white-space: pre-wrap;
  word-break: break-word;
`;

const Code = styled.code<{
  _textColor: string;
}>`
  ${({ theme }) => theme.font.base};
  ${({ theme }) => theme.font.caption};
  color: ${({ _textColor }) => _textColor};
`;

const Copied = styled.div`
  ${({ theme }) => theme.font.caption};
  padding: 6px 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`;
