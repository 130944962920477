import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  VERIFY_MFA_DIGIT_PATH,
  VerifyMFADigitResponseError,
  VerifyMFADigitRequest
} from '@elfsight-universe/service-core-contracts/identity-and-access';
import { client } from '../client';

export function useVerifyMFADigitMutation(
  options: UseMutationOptions<
    Response,
    VerifyMFADigitResponseError,
    VerifyMFADigitRequest
  > = {}
) {
  return useMutation<
    Response,
    VerifyMFADigitResponseError,
    VerifyMFADigitRequest
  >((data) => client.post(VERIFY_MFA_DIGIT_PATH, { json: data }), {
    ...options,
    retry: false
  });
}
