import { Exclude, Expose } from 'class-transformer';

export const START_MFA_CONFIGURATION_PATH =
  'identity-and-access/start-mfa-configuration';

@Exclude()
export class StartMFAConfigurationResponse {
  @Expose()
  url: string;

  @Expose()
  secret: string;
}
