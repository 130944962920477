import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_LAST_MODIFIED_WIDGET_PATH,
  GetLastModifiedWidgetResponse
} from '@elfsight-universe/service-core-contracts/widget';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useLastModifiedWidgetQuery(
  appAlias?: string,
  options: UseQueryOptions<GetLastModifiedWidgetResponse> = {}
) {
  const { isAuthenticated } = useUser();

  return useQuery<GetLastModifiedWidgetResponse>(
    [GET_LAST_MODIFIED_WIDGET_PATH, appAlias],
    () =>
      client
        .get(GET_LAST_MODIFIED_WIDGET_PATH, {
          searchParams: appAlias ? { appAlias } : {}
        })
        .json<GetLastModifiedWidgetResponse>(),
    {
      enabled: isAuthenticated,
      ...options
    }
  );
}
