import styled, { css } from 'styled-components';
import { ThemeColor } from '../../theme';

type ControlStyleProps = {
  _fontSize?: number;
  _height?: number;
  _hasError?: boolean;
  _fullWidth?: boolean;
  _maxWidth?: number;
  _padding?: number;
  _focused?: boolean;
  _borderColor?: ThemeColor;
};

export const ControlBaseInner = styled.div``;

export const ControlInner = styled(ControlBaseInner)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  transition: border-color 0.2s;
  overflow: hidden;
`;

export const ControlInput = styled.input`
  ${({ theme }) => theme.font.text};
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  outline: none;
  border: none;
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;

  &[type=number]::-webkit-outer-spin-button,
  &[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance:textfield;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray30};
  }
`;

export const ControlTextarea = styled.textarea`
  ${({ theme }) => theme.font.text};
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  resize: none;
  outline: none;
  border: none;
  border-radius: 4px;
  -webkit-tap-highlight-color: transparent;

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray30};
  }
`;

export const ControlLabel = styled.label`
  display: block;
  overflow: hidden;
  ${({ theme }) => theme.font.title4};
  color: ${({ theme }) => theme.colors.black};
`;

export const ControlCaption = styled.div`
  overflow: hidden;
  ${({ theme }) => theme.font.caption};
  color: ${({ theme }) => theme.colors.gray50};
`;

export const ControlError = styled.div`
  ${({ theme }) => theme.font.text};
  color: ${({ theme }) => theme.colors.alert};
  margin-top: 4px;
  text-align: left;
  overflow: hidden;
`;

export const ControlContainer = styled.div<ControlStyleProps>`
  position: relative;
  border: none;
  border-radius: 4px;
  max-width: ${({ _maxWidth }) => (_maxWidth ? `${_maxWidth}px` : '100%')};
  width: ${({ _fullWidth }) => (_fullWidth ? '100%' : 'auto')};

  ${ControlBaseInner} {
    font-size: ${({ _fontSize = 14 }) => _fontSize}px;
    ${({ _height }) =>
      _height &&
      css`
        height: ${_height}px;
      `}
  }

  ${ControlInner} {
    ${({
      _focused,
      _hasError,
      _borderColor,
      theme: {
        colors: { alert, gray30, gray50 }
      }
    }) =>
      _focused
        ? css`
            border-color: ${_hasError ? alert : gray50};
          `
        : css`
            border-color: ${_hasError ? alert : _borderColor || gray30};
            :hover {
              border-color: ${_hasError ? alert : gray50};
            }
          `}
  }

  ${ControlTextarea} {
    padding: 8px ${({ _padding = 12 }) => _padding}px;
  }
  ${ControlInput} {
    padding: 0 ${({ _padding = 12 }) => _padding}px;
  }

  ${ControlLabel} + ${ControlCaption} {
    margin-top: 4px;
  }

  ${ControlLabel} + ${ControlBaseInner},
  ${ControlCaption} + ${ControlBaseInner} {
    margin-top: 8px;
  }

  ${ControlBaseInner} + ${ControlCaption} {
    margin-top: 8px;
  }

  ${({
    theme: {
      colors: { black, white }
    }
  }) => css`
    background: ${white};
    color: ${black};
  `}
`;
