import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_WIDGET_INFO_PATH,
  GetWidgetInfoResponse,
  GetWidgetInfoResponseError
} from '@elfsight-universe/service-core-contracts/widget/get-widget-info';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useWidgetInfoQuery(
  pid: string | undefined,
  options: UseQueryOptions<
    GetWidgetInfoResponse,
    GetWidgetInfoResponseError
  > = {}
) {
  const { isAuthenticated } = useUser();

  return useQuery<GetWidgetInfoResponse, GetWidgetInfoResponseError>(
    [GET_WIDGET_INFO_PATH, pid],
    () =>
      client
        .get(GET_WIDGET_INFO_PATH, {
          searchParams: { widgetPid: pid as string },
          retry: { limit: 0 }
        })
        .json(),
    {
      enabled: Boolean(pid) && isAuthenticated,
      ...options
    }
  );
}
