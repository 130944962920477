import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  START_MFA_CONFIGURATION_PATH,
  StartMFAConfigurationResponse
} from '@elfsight-universe/service-core-contracts/identity-and-access';
import { client } from '../client';

export function useStartMFAConfigurationMutation(
  options: UseMutationOptions<StartMFAConfigurationResponse> = {}
) {
  return useMutation<StartMFAConfigurationResponse>(
    () =>
      client
        .post(START_MFA_CONFIGURATION_PATH)
        .json<StartMFAConfigurationResponse>(),
    options
  );
}
