import { Exclude, Expose, Type } from 'class-transformer';
import { IsUUID } from 'class-validator';
import { WidgetError } from '../errors';

export const GET_WIDGET_INFO_PATH = 'widget/get-widget-info';

export class GetWidgetInfoRequest {
  @IsUUID()
  widgetPid: string;
}

@Exclude()
export class GetWidgetInfoResponse {
  @Expose()
  name: string;

  @Expose()
  @Type(() => GetWidgetInfoResponseApp)
  app: {
    name: string;
    alias: string;
  };
}

@Exclude()
export class GetWidgetInfoResponseApp {
  @Expose()
  name: string;

  @Expose()
  alias: string;
}

export type GetWidgetInfoResponseError = WidgetError.WIDGET_DOES_NOT_EXIST;
