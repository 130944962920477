import { ReactNode } from 'react';
import { AccountDeal } from '@elfsight-universe/service-core-contracts/deals';
import {
  BIRTHDAY_DEAL_ALIAS,
  BLACK_FRIDAY_DEAL_ALIAS,
  WELCOME_DEAL_ALIAS,
  XMAS_DEAL_ALIAS
} from '@modules/deals/utils/deal-aliases';

export const getDealCaption = ({ deal, data }: AccountDeal) => {
  const dealCaptionMapping: Record<string, string | ReactNode> = {
    [BIRTHDAY_DEAL_ALIAS]: data.eligibleForInstantExtension ? (
      <>
        Extend your subscription with <b>33%&nbsp;OFF</b> and enjoy{' '}
        <b>1 bonus month!</b>
        <br />
        <br />
      </>
    ) : (
      <>
        Enjoy <b>33%&nbsp;OFF</b> any annual subscription.
        <br />
        <br />
      </>
    ),
    [BLACK_FRIDAY_DEAL_ALIAS]: data.eligibleForInstantExtension ? (
      <>
        It&apos;s here - the biggest Elfsight sale. Extend your current
        subscription with <b>50%&nbsp;discount</b> and get{' '}
        <b>1&nbsp;month free</b>. Don&apos;t miss this once-a-year chance!
      </>
    ) : (
      <>
        It&apos;s here - the biggest Elfsight sale. Subscribe to any annual
        subscription with <b>50%&nbsp;discount</b>. Don&apos;t miss this
        once-a-year chance!
      </>
    ),
    [XMAS_DEAL_ALIAS]: data.eligibleForInstantExtension ? (
      <>
        Extend your subscription with <b>33% OFF</b> and enjoy{' '}
        <b>1 bonus month</b>! Catch the end-of-year sale!
      </>
    ) : (
      <>
        Enjoy <b>33% OFF</b> any annual subscription. Catch the end-of-year
        sale!
      </>
    ),
    [WELCOME_DEAL_ALIAS]: (
      <>
        Enjoy <b>33% OFF</b> any annual subscription. Catch the offer before
        it’s gone!
        <br />
        <br />
      </>
    )
  };

  return dealCaptionMapping[deal.alias] || deal.caption;
};
