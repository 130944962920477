import styled from 'styled-components';
import { AnnouncementsCardSkeleton } from '@modules/announcements/announcements-card-skeleton';
import { AnnouncementsNewDivider } from '@modules/announcements/components/announcements-new-divider';
import { AnnouncementCard } from './announcements-card';
import {
  ANNOUNCEMENTS_PER_PAGE,
  useAnnouncementsContext
} from './announcements-provider';

export function AnnouncementsList() {
  const { data, numberOfUnreadAnnouncements, isLoading } =
    useAnnouncementsContext();

  const lastReadAnnouncementPage =
    Math.trunc(numberOfUnreadAnnouncements / ANNOUNCEMENTS_PER_PAGE) + 1;
  const lastReadAnnouncementIndex =
    numberOfUnreadAnnouncements % ANNOUNCEMENTS_PER_PAGE;

  return (
    <Container>
      {data?.pages.map((announcementsResponse) =>
        announcementsResponse.payload.map((announcement, index) => {
          const showDivider =
            numberOfUnreadAnnouncements > 0 &&
            announcementsResponse.meta.currentPage ===
              lastReadAnnouncementPage &&
            index === lastReadAnnouncementIndex;

          return (
            <CardWrapper key={announcement.pid}>
              {showDivider && <StyledAnnouncementsNewDivider />}
              <AnnouncementCard announcement={announcement} />
            </CardWrapper>
          );
        })
      )}
      {isLoading && <AnnouncementsCardSkeleton />}
    </Container>
  );
}

const Container = styled.div``;

const CardWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 8px;
  }`;

const StyledAnnouncementsNewDivider = styled(AnnouncementsNewDivider)`
  margin-top: -8px;
`;
