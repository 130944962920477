export const BEACON_ID = '04d8c670-f814-4da9-bc64-466401517f29'; // @TODO move into .env
export const BEACON_AFFILIATE_ID = '6c39afa2-3828-42be-80a0-0ce9679b9b35'; // @TODO move into .env

export const beaconMessages = {
  welcomeMessage: '7c655835-14ac-4a6d-8289-4a3b4b4eab21',
  welcomeMessageFromDemo: 'd8d2ca49-8ea0-4bba-8e77-3f43e0bd0cef',
  extraYearMessage: '7b00309a-af46-4366-b1f7-c1f562edbba9'
} as const;

export const helpscoutArticles = {
  views: {
    id: '6298722a5732000792520c76',
    url: ''
  },
  coupon: {
    id: '5dd7883404286364bc9210c0',
    url: 'https://help.elfsight.com/article/272-how-to-use-your-elfsight-apps-discount-coupon'
  },
  projects: {
    id: '6572ff4ec893e53862feb66a',
    url: 'https://help.elfsight.com/article/1273-projects-getting-started'
  },
  mfa: {
    id: '6752c6009672e65c98572abf',
    url: 'https://help.elfsight.com/article/1466-how-to-set-up-2fa'
  }
} as const;
