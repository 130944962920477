import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  ENABLE_MFA_PATH,
  EnableMFAError,
  EnableMFARequest
} from '@elfsight-universe/service-core-contracts/identity-and-access';
import { client } from '../client';

export function useEnableMFAMutation(
  options: UseMutationOptions<Response, EnableMFAError, EnableMFARequest> = {}
) {
  return useMutation<Response, EnableMFAError, EnableMFARequest>(
    (data) => client.post(ENABLE_MFA_PATH, { json: data }),
    {
      ...options,
      retry: false
    }
  );
}
