import { Exclude, Expose, Type } from 'class-transformer';
import { IsOptional, IsString } from 'class-validator';

export const GET_LAST_MODIFIED_WIDGET_PATH = 'widget/get-last-modified-widget';

export class GetLastModifiedWidgetRequest {
  @IsString()
  @IsOptional()
  appAlias?: string;
}

@Exclude()
export class GetLastModifiedWidgetResponse {
  @Expose()
  @Type(() => GetLastModifiedWidgetResponseWidget)
  widget: {
    pid: string;
    name: string;
  } | null;
}

@Exclude()
export class GetLastModifiedWidgetResponseWidget {
  @Expose()
  pid: string;

  @Expose()
  name: string;

  @Expose()
  @Type(() => GetLastModifiedWidgetResponseWidgetApp)
  app: {
    name: string;
    alias: string;
  };
}

@Exclude()
export class GetLastModifiedWidgetResponseWidgetApp {
  @Expose()
  name: string;

  @Expose()
  alias: string;
}
