import {
  DEFAULT_SERVICE_CORE__BASE_URL,
  PLATFORM_SCRIPT_URL
} from './constants';

// @TODO add .spec

export interface GenerateWidgetCodeProps {
  widgetPid: string;
  serviceCorePlatformURL?: string;
  widgetName: string;
  appName: string;
}

export const generateWidgetCode = ({
  widgetPid,
  serviceCorePlatformURL = DEFAULT_SERVICE_CORE__BASE_URL,
  widgetName,
  appName
}: GenerateWidgetCodeProps) => {
  const isDefaultPlatform =
    serviceCorePlatformURL === DEFAULT_SERVICE_CORE__BASE_URL;

  return [
    `<!-- Elfsight ${appName} | ${widgetName} -->`,
    !isDefaultPlatform
      ? `<script>window.eappsCustomPlatformUrl = "${serviceCorePlatformURL}";</script>`
      : undefined,
    `<script src="${PLATFORM_SCRIPT_URL}" async></script>`,
    `<div class="elfsight-app-${widgetPid}" data-elfsight-app-lazy></div>`
  ]
    .filter((value) => value !== undefined)
    .join('\n');
};
