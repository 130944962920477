import { ComponentType, forwardRef, HTMLAttributes } from 'react';
import { ThemeColor } from '../../theme';
import { IconContainer } from './icon-container';

type ThemeColorOrString = ThemeColor | 'currentColor' | 'transparent' | string;

export type IconProps = HTMLAttributes<HTMLDivElement> & {
  stroke?: ThemeColorOrString;
  fill?: ThemeColorOrString;
  component: ComponentType;
  size: number;
};

export const Icon = forwardRef<HTMLDivElement, IconProps>(function Icon(
  {
    component: IconComponent,
    size,
    fill = 'currentColor',
    stroke,
    ...forwardingProps
  },
  forwardingRef
) {
  return (
    <IconContainer
      _fill={fill}
      _stroke={stroke}
      _size={size}
      ref={forwardingRef}
      {...forwardingProps}
    >
      {IconComponent && <IconComponent />}
    </IconContainer>
  );
});
