import { forwardRef, MouseEvent, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Placement } from '@floating-ui/react';
import { More } from '@elfsight/icons';
import { Popover, useTheme } from '@elfsight-universe/ui-common';
import { IconButton } from '../../buttons';
import { Tooltip } from '../tooltip';
import { ContextMenuContext } from './context-menu-context';

export type ContextMenuProps = {
  children: ReactNode;
  largeButton?: boolean;
  primaryButton?: boolean;
  disabledButton?: boolean;
  tooltipContent?: ReactNode;
  placement?: Placement;
};

export const ContextMenu = forwardRef<HTMLElement, ContextMenuProps>(
  function ContextMenu(
    {
      children,
      largeButton,
      primaryButton,
      disabledButton = false,
      tooltipContent = 'More Actions',
      placement = 'bottom-start',
      ...forwardingProps
    },
    forwardingRef
  ) {
    const [isOpen, setOpen] = useState(false);
    const { zIndex: themeZIndex } = useTheme();

    const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setOpen(!isOpen);
    };

    return (
      <ContextMenuContext.Provider value={{ isOpen, setOpen }}>
        <Popover
          isOpen={isOpen}
          onOpenChange={setOpen}
          placement={placement}
          containerStyle={{
            zIndex: themeZIndex.contextMenuDropdown.toString()
          }}
          preventFocus
          content={
            <Content
              onClick={(e: MouseEvent) => e.stopPropagation()}
              {...forwardingProps}
            >
              {children}
            </Content>
          }
        >
          <Tooltip
            preventOpen={isOpen || disabledButton}
            content={tooltipContent}
          >
            <IconButton
              disabled={disabledButton}
              icon={More}
              large={largeButton}
              primary={primaryButton}
              active={isOpen}
              stayFocused
              onClick={handleOpen}
            />
          </Tooltip>
        </Popover>
      </ContextMenuContext.Provider>
    );
  }
);

const Content = styled.div`
  padding: 8px 0;
`;
