import { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';
import {
  ControlCaption,
  ControlContainer,
  ControlError,
  ControlInner,
  ControlLabel
} from './control-layout';
import { ControlProps } from './control-types';

export type ExtendedControlProps = HTMLAttributes<HTMLDivElement> &
  PropsWithChildren<
    ControlProps<{
      height?: number;
      className?: string;
      fontSize?: number;
      focused?: boolean;
      padding?: number;
      labelProps?: HTMLAttributes<HTMLLabelElement>;
    }>
  >;

export const Control = forwardRef<HTMLDivElement, ExtendedControlProps>(
  function Control(
    {
      children,
      label,
      captionTop,
      captionBottom,
      fullWidth,
      maxWidth,
      height,
      fontSize,
      padding,
      error,
      focused,
      labelProps = {},
      ...forwardingProps
    },
    forwardingRef
  ) {
    const hasError = Boolean(error);

    return (
      <ControlContainer
        _fullWidth={fullWidth}
        _maxWidth={maxWidth}
        _fontSize={fontSize}
        _height={height}
        _hasError={hasError}
        _focused={focused}
        _padding={padding}
        {...forwardingProps}
      >
        {label && <ControlLabel {...labelProps}>{label}</ControlLabel>}
        {captionTop && <ControlCaption>{captionTop}</ControlCaption>}

        <ControlInner ref={forwardingRef}>{children}</ControlInner>

        {captionBottom && <ControlCaption>{captionBottom}</ControlCaption>}
        {error && <ControlError>{error}</ControlError>}
      </ControlContainer>
    );
  }
);
