import { IsNotEmpty, IsString } from 'class-validator';
import { IdentityAndAccessError } from '../../errors';

export const ENABLE_MFA_PATH = 'identity-and-access/enable-mfa';

export class EnableMFARequest {
  @IsNotEmpty({ message: 'Please enter the code.' })
  @IsString()
  digit: string;
}

export type EnableMFAError =
  | IdentityAndAccessError.CAN_NOT_ENABLE_MFA
  | IdentityAndAccessError.MFA_VERIFICATION_FAILED;
