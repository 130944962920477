import { IsNotEmpty, IsString } from 'class-validator';
import { IdentityAndAccessError } from '../../errors';

export const VERIFY_MFA_DIGIT_PATH = 'identity-and-access/verify-mfa-digit';

export class VerifyMFADigitRequest {
  @IsNotEmpty({ message: 'Please enter the code.' })
  @IsString()
  digit: string;
}

export type VerifyMFADigitResponseError =
  | IdentityAndAccessError.NOT_AUTHORIZED
  | IdentityAndAccessError.MFA_VERIFICATION_FAILED;
