import styled, { useTheme } from 'styled-components';
import {
  DESKTOP,
  MOBILE,
  ModalWithScrollableBody,
  ModalWithScrollableBodyProps,
  TabsNav,
  TabsProvider
} from '@elfsight-universe/ui-common';
import { useReplaceMultipleQueryParams } from '@utils';
import { DISABLED_SHARE_LINK_APP_ALIASES } from '@constants';
import { useIsMobile } from '@modules/_app';
import { EmbedToWixTab } from '@modules/widget-install/embed-to-wix/embed-to-wix-tab';
import { useWixIntegration } from '@modules/wix-integration/contexts/wix-integration';
import { EmbedToWebsiteTab } from './embed-code';
import { ShareLinkTab } from './share-link';
import {
  WIDGET_MANUALLY_INSTALL_BOOLEAN_QUERY_PARAM,
  WIDGET_INSTALL_TAB_QUERY_PARAM,
  WidgetInstallTab
} from './widget-install-constants';
import { RequestInstallationTab } from './request-installation';
import { WidgetInstallData } from './widget-install-data.interface';
import { RequestInstallationFormProvider } from './request-installation/request-installation-form-provider';

export type WidgetInstallModalProps = ModalWithScrollableBodyProps &
  WidgetInstallData & {
    disableHelpTab?: boolean;
  };

export const WIDGET_INSTALL_PID_QUERY_PARAM = 'installationWidgetPid';

export function WidgetInstallModal({
  isOpen = false,
  onRequestClose,
  widgetPid,
  widgetName,
  isDraft,
  onPublishDraft,
  isPublishingDraft,
  appAlias,
  appName,
  plan,
  disableHelpTab = false,
  ...forwardingProps
}: WidgetInstallModalProps) {
  const isMobile = useIsMobile();
  const { zIndex } = useTheme();
  const [queryParams, replaceQueryParams] = useReplaceMultipleQueryParams([
    WIDGET_INSTALL_TAB_QUERY_PARAM,
    WIDGET_INSTALL_PID_QUERY_PARAM,
    WIDGET_MANUALLY_INSTALL_BOOLEAN_QUERY_PARAM
  ]);

  const wixIntegration = useWixIntegration();
  const data = {
    widgetPid,
    appAlias,
    isDraft,
    onPublishDraft,
    isPublishingDraft,
    plan,
    widgetName,
    appName
  };

  const isDisabledShareLink =
    DISABLED_SHARE_LINK_APP_ALIASES.includes(appAlias);

  const widgetInstallTabs = [
    {
      title: 'Embed to Wix',
      id: WidgetInstallTab.WIX,
      disabled: !wixIntegration.isWixSession
    },
    {
      title: 'Embed Code',
      id: WidgetInstallTab.EMBED,
      disabled: false
    },
    {
      title: 'Share Link',
      id: WidgetInstallTab.SHARE,
      disabled: isDisabledShareLink
    },
    {
      title: isMobile ? 'Help' : 'Request Installation',
      id: WidgetInstallTab.REQUEST,
      disabled: disableHelpTab
    }
  ];

  const widgetInstallTabsContent = {
    [WidgetInstallTab.WIX]: (
      <EmbedToWixTab tabID={WidgetInstallTab.WIX} widgetPid={widgetPid} />
    ),
    [WidgetInstallTab.EMBED]: (
      <EmbedToWebsiteTab tabID={WidgetInstallTab.EMBED} {...data} />
    ),
    [WidgetInstallTab.SHARE]: (
      <ShareLinkTab tabID={WidgetInstallTab.SHARE} {...data} />
    ),
    [WidgetInstallTab.REQUEST]: (
      <RequestInstallationTab tabID={WidgetInstallTab.REQUEST} />
    )
  };

  const finalInstallTabs = widgetInstallTabs.filter(
    ({ disabled }) => !disabled
  );
  const finalInstallTabsContent = finalInstallTabs.map(({ id }) => ({
    ...widgetInstallTabsContent[id],
    key: id
  }));

  return (
    <TabsProvider
      tabs={finalInstallTabs}
      defaultTab={
        wixIntegration.isWixSession
          ? WidgetInstallTab.WIX
          : WidgetInstallTab.EMBED
      }
      queryParam={queryParams.installation}
      onSetActiveTab={(value: string) => {
        replaceQueryParams({
          [WIDGET_INSTALL_TAB_QUERY_PARAM]: value
        });
      }}
    >
      <ModalWithScrollableBody
        isOpen={isOpen}
        maxWidth={860}
        onRequestClose={(e) => {
          onRequestClose && onRequestClose(e);
          replaceQueryParams({
            [WIDGET_INSTALL_TAB_QUERY_PARAM]: undefined,
            [WIDGET_INSTALL_PID_QUERY_PARAM]: undefined,
            [WIDGET_MANUALLY_INSTALL_BOOLEAN_QUERY_PARAM]: undefined
          });
        }}
        withClose
        padding={[24, 0]}
        zIndex={zIndex.installModal}
        header={
          <StyledTabsNav
            withBorder
            largeItems={!isMobile}
            height={isMobile ? 48 : 60}
          />
        }
        {...forwardingProps}
      >
        <RequestInstallationFormProvider
          widgetPid={widgetPid}
          appAlias={appAlias}
        >
          <ContentBody>{finalInstallTabsContent.map((tab) => tab)}</ContentBody>
        </RequestInstallationFormProvider>
      </ModalWithScrollableBody>
    </TabsProvider>
  );
}

const ContentBody = styled.div`
  box-sizing: border-box;
  padding: 28px 32px 0;
  display: flex;
  flex-direction: column;
  min-height: 655px;
`;

const StyledTabsNav = styled(TabsNav)`
  padding: 0 32px;
  flex-shrink: 0;

  ul {
    ${DESKTOP} {
      gap: 0 40px;
    }
    ${MOBILE} {
      gap: 0 28px;
    }
  }
`;
